<template>
  <div class="d-block">
    <b-button
      size="sm"
      :variant="!changed ? 'primary' : 'success'"
      @click="syncTime"
    >
      sync time
    </b-button>
    <a :href="url" v-if="url" target="_blank">
      {{ url }}
    </a>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import Api from '@/components/mixins/api.js'

export default {
  mixins: [Api],
  data() {
    return {
      changed: false,
      url: null
    }
  },

  methods: {
    syncTime() {
      this.apiGet({
        type: 'function',
        driver: 'piec',
        func: 'timesetup',
        params: `czas|${dayjs().format('HH:mm:ss|DD-MM-YYYY')}`
      }).then(r => {
        this.changed = true
        this.url = r.url
        // console.log('time changed', r.url)
      })
    }
  }
}
</script>
