var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "my-3"
  }, [_c('sync-time'), _vm._v(" Czas na piecu: "), _c('strong', [_vm._v(" " + _vm._s(_vm.driverTime.hour) + ":" + _vm._s(_vm.driverTime.minute) + " ")])], 1), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('SwitchBox', {
    attrs: {
      "driver": "garaz",
      "pin": "27"
    }
  })], 1)]), _c('div', [_c('div', [_vm._v("Logger:")]), _c('div', [_c('pre', [_vm._v("        " + _vm._s(_vm.logger) + "\n      ")])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('h4', [_vm._v("Drivers data")]), _c('pre', {
    staticStyle: {
      "color": "#fff"
    }
  }, [_vm._v("       " + _vm._s(_vm.driversData) + "\n      ")])]), _c('div', {
    staticClass: "col-sm-6"
  }, [_c('h4', [_vm._v("Scheduler raw data")]), _c('pre', {
    staticStyle: {
      "color": "#fff"
    }
  }, [_vm._v("       " + _vm._s(_vm.schedulerData) + "\n      ")])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }