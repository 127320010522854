<template>
  <div class="container">
    <div class="my-3">
      <sync-time />
      Czas na piecu:
      <strong> {{ driverTime.hour }}:{{ driverTime.minute }} </strong>
    </div>

    <div class="row">
      <div class="col-xs-12 col-md-6 col-lg-4">
        <SwitchBox driver="garaz" pin="27" />
      </div>
    </div>

    <div>
      <div>Logger:</div>
      <div>
        <pre>
          {{ logger }}
        </pre>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <h4>Drivers data</h4>
        <pre style="color: #fff">
         {{ driversData }}
        </pre>
      </div>
      <div class="col-sm-6">
        <h4>Scheduler raw data</h4>
        <pre style="color: #fff">
         {{ schedulerData }}
        </pre>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SyncTime from '@/components/settings/sync-time.vue'

export default {
  name: 'Settings',
  components: {
    SyncTime
  },

  computed: {
    schedulerData() {
      return this.$store.state.schedulerData
    },

    driversData() {
      return this.$store.state.driversData
    },

    logger() {
      return this.$store.state.logger || []
    },

    driverTime() {
      const { hour, minute } = this.driversData.piec || {}
      return {
        hour,
        minute
      }
    }
  }
}
</script>
