var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-block"
  }, [_c('b-button', {
    attrs: {
      "size": "sm",
      "variant": !_vm.changed ? 'primary' : 'success'
    },
    on: {
      "click": _vm.syncTime
    }
  }, [_vm._v(" sync time ")]), _vm.url ? _c('a', {
    attrs: {
      "href": _vm.url,
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.url) + " ")]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }